import React from "react"
import Layout from "../components/layout"
import Image  from "../components/image"
import SEO from "../components/seo"
import ContactForm from "../components/contact"
import {Container,Row,Col,Card} from 'react-bootstrap'



const AboutPage = ()=>{
    return(
        <Layout>
            <SEO title="Who-We-Are" />
            <div sm={12} lg={4} className="homeAuto">
                <Image alt="who we are" filename="whoweare.png" />
                <div className="abtImage">
                    <h1 style={{fontWeight:"bold"}} className="whov">WHO WE ARE</h1>
                </div>
            </div>

            <Container >
                <Row className="aboutrow">
                    <Col sm={12} lg={4} className="crmCardCol">
                        <h3 className="h3">VALUES AND PURPOSE</h3>
                        <div className="aboutPara">Trace Width Technology solutions is committed to improving the quality of life of the communities we serve. We do this by striving for leadership and global competitiveness in the business sectors in which we operate. We have been value-driven and look forward to long-term value creation to achieve our goals.</div>
                    </Col>
                    <Col sm={12} lg={4} className="crmCardCol">
                        <h3 className="h3">INNOVATION</h3>
                        <div className="aboutPara">We believe in innovation of approaches, thoughts, processes and even strategies. This helps us to remain future ready. We take much pride in fostering an inspiring workplace, with an agile and high performance culture. We are also deeply committed to recognizing and valuing diversity across our teams.</div>
                    </Col>
                    <Col sm={12} lg={4} className="crmCardCol">
                        <h3 className="h3">ORGANIZATION STRUCTURE</h3>
                        <div className="aboutPara">Trace Width Technology Solutions is a company with a global footprint. The driving force of the organization across its global operations is its managers, empowered with drive and flexibility in decision-making and execution.</div>
                    </Col>
                </Row>
                <hr className="hrhalf"/>
            </Container>

            <Container>
                <h3 className="aboutTitle">Trace Width can help produce affordable technology for the mobile space. We provide one of the best software services for your business.</h3>
                <Row>
                    <Col sm={12} lg={3} className="aboutCol crmCardCol">
                        <Image alt="requirement" filename="requirements.png" style={{width: '100px'}}  />
                        <div>
                            <h3 className="abouth3">WHY TRACE WIDTH</h3>
                            <p className="aboutPara1">Trace Width Technologies has expertise in processes related to Project Engineering, Implementation and Planning. We utilize the best talent and deliver cutting edge solutions to our clients, helping you discover the power of certainty. We are a pioneer in the global arcade within the world.</p>
                        </div>
                    </Col>
                    <Col sm={12} lg={3} className="aboutCol crmCardCol" >
                        <Image alt="develop" filename="develop.png" style={{width: '100px'}} />
                        <div>
                            <h3 className="abouth3">CORPORATE</h3>
                            <p className="aboutPara1">Trace Width Technologies addresses the complex issue of delivering efficient growth and operational performance by ensuring robust strategy and planning mechanisms. At Trace Width Technologies, we believe our responsibilities extend beyond businesses. We maintain both the ethics as well as honesty in all our interactions.</p>
                        </div>
                    </Col>
                    <Col sm={12} lg={3}  className="aboutCol crmCardCol">
                        <Image alt="testing" filename="testing-1.png" style={{width: '100px'}}/>
                        <div>
                            <h3 className="abouth3">TECHNICAL EXPERTISE</h3>
                            <p className="aboutPara1">Trace Width Technologies offers a full spectrum of technology services. We help you harness the power of emerging technologies while getting the most out of your legacy IT. We combine business and industry insights with technology know-how. In today’s demanding business environment which prioritizes flexibility, speed, and efficiency, a robust IT strategy is critical.</p>
                        </div> 
                    </Col>
                    <Col sm={12} lg={3} className="aboutCol crmCardCol">
                        <Image alt="product" filename="product-1.png" style={{width: '100px'}}/>
                        <div>
                            <h3 className="abouth3">SUSTAINABILITY</h3>
                            <p className="aboutPara1">We believe that technology is a key component for accelerating the global sustainability program. Our technological expertise helps you achieve the required domain expertise in sustainability. This in turn leads you to receive the most out of technology solutions. A key element of our solutions includes secure access to data along with customizing your user experience.</p>
                        </div> 
                    </Col>
                </Row>
                <hr className="hrhalf"/>
            </Container>

            <Container>
            <h3 className="homeCardrow3">Trace Width Technologies partners</h3>
                <Row className="homeCardrow1">
                    
                    <Col sm={12} lg={3}>
                        <Card className="cardBody">
                            <Image alt="aws" filename="aws.png" style={{width: '150px', margin:"0 50px"}} className="cardImage"/>
                        </Card>
                    </Col>
                    <Col sm={12} lg={3}>
                        <Card className="cardBody">
                            <Image alt="cloudbees" filename="cloudbees.png" style={{width: '150px',margin:"0 50px"}} />
                        </Card>
                    </Col>
                    <Col sm={12} lg={3}>
                        <Card className="cardBody">
                            <Image alt="ibm" filename="ibm.png" style={{width: '150px',margin:"0 50px"}} />
                        </Card>
                    </Col>
                    <Col sm={12} lg={3}>
                        <Card className="cardBody">
                            <Image alt="googleengine" filename="googleengin.png" style={{width: '150px',margin:"0 50px"}}/>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <div className="headerForm"> 
                <br/>
                <div className="homeCardrow5">Let us know your project needs and let’s make your vision a reality</div>
                <ContactForm  />
            </div>
            
           
        </Layout>
    )
}


export default AboutPage